<template>
  <div class="apparea">
    <div class="col-md-9 filter-cont dVacation-pack vh-100 m-auto" v-if="isLoading || hotels.length === 0">
      <ContentLoading type="product" />
    </div>
    <div v-else class="product-content">
      <!----- header area start ----->
      <header-desktop />
      <!----- header area stop ----->
      <main class="body_warrper pt-5">
        <div class="row container m-auto">
          <!------ kremlin area start ------>
          <section class="kremlin-area col-lg-6 px-2 pb-5">
            <div class="container">
              <!------- heading area start ------->
              <div class="heading">
                <h1>{{ hotelName }}</h1>
                <div class="d-flex retingbox" v-if="rating>2">
                  <b-form-rating
                    class="ui rating"
                    v-model="rating"
                    variant="warning"
                    locale="he"
                    readonly
                  ></b-form-rating>
                </div>
                <h3>{{ basisName }}</h3>
              </div>
              <!------- heading area stop ------->
              <!------ kremlin list area start ------->
              <div class="kremlin-list">
                <ul class="d-flex flex-row">
                  <li>
                    <figure class="kremlin-box">
                      <img :src="`${speedSizeDomain}/assets/img/app/icon3.png`" alt="#" title="" />
                      <figcaption>{{ addressString }}</figcaption>
                    </figure>
                  </li>
                  <li>
                    <figure class="kremlin-box">
                      <img :src="`${speedSizeDomain}/assets/img/app/icon2.png`" alt="#" title="" />
                      <figcaption>{{betweenDateString}}</figcaption>
                    </figure>
                  </li>
                  <li>
                    <figure class="kremlin-box">
                      <img :src="`${speedSizeDomain}/assets/img/app/icon1.png`" alt="#" title="" />
                      <figcaption>{{personString}}</figcaption>
                    </figure>
                  </li>
                </ul>
              </div>
              <!------ kremlin list area stop ------->
            </div>
            <CollapseList :data="product" :categoryName="categoryCode" />
          </section>
          <!------ kremlin area stop ------>
          <!-------- banner area start -------->
          <div class="container col-lg-6 px-2">
            <section class="banner-area">
              <div class="container">
                <div class="banner">
                  <carousel :slides="imageList" :showThumb="true" :numThumb="4" :defaultImage="destinationImage" :altText="hotelName" v-show="imageList.length>0" />
                </div>
              </div>
            </section>
          </div>
          <!-------- banner area stop -------->
        </div>

        <!------- dayily area start ------->
        <section class="daily-area" v-if="categoryCode === 'Organize_tour_packages'">
          <div class="heading-box">
            <div class="container">
              <h4>מסלול הטיול</h4>
            </div>
          </div>
          <ReadMore :message="description_t1" :mobileShow="false" moreClass="bold-text" />
          <ReadMore :fixedText="description_t2_fixed" :message="description_t2" :mobileShow="false" />
        </section>
        <!------- daily area stop ------->

        <!------ plaindetails area start ------->
        <div class="flight-area">
          <h3>פרטי הטיסה</h3>
          <section class="unavailable-flight mt-3" v-if="!availableFlight">
            <div class="container">
              <div class="plaindetails-box">
                <h5>{{ $t("flight-only.unavailable-flight") }}</h5>
              </div>
            </div>
          </section>
          <section class="flights-list">
            <div class="container" v-if="flightItems.length>0">
              <flight-item-app
                :flight="flightItems[idxFlight]"
                :baggagePriceInfo="baggagePriceInfo"
                :categoryName="categoryCode"
                type="product"
              />
              <div class="select-flight d-flex"  v-if="!isOrganizeTour && flightItems.length>1 && !isOdysseySite">
                <div class="selectflhtbtn m-auto btn-primary" id="selectflhtbtn" v-b-toggle="`filter-content`">
                  <img :src="`${speedSizeDomain}/assets/img/flight.png`" alt="" />{{ $t('product-page.more-flight-option') }}
                </div>
              </div>
              <b-collapse class="filter-cont dVacation-pack" :id="`filter-content`" v-model="visibleMoreFlights">
                <flight-item-app
                  v-for="(item, inx) in flightItems"
                  :key="inx"
                  :flight="item"
                  :baggagePriceInfo="baggagePriceInfo"
                  :idx="inx"
                  :selectIdx="idxFlight"
                  :categoryName="categoryCode"
                  type="product"
                  @change="changeFlightIndex"
                />
              </b-collapse>
            </div>
          </section>
        </div>
        <!------ plaindetails area stop ------->

        <div class="price-area">
            <h3>אנא בחר את החדר המתאים</h3>
            <price-area-app :product="product" @message="messageFromChild" :hotelName="hotelName" :destinationCityName="destinationName" :includesText="includesText"/>
        </div>
      </main>

      <footer-desktop  :backLink="backLink"/>
    </div>
  </div>
</template>

<script>
import { BFormRating, VBToggle, BCollapse } from 'bootstrap-vue';
import dayjs from 'dayjs';
import bannerImageDestination from '@/utils/bannerImageDestination';
import imageUrlMixin from '@/utils/imageUrlMixin';

import TemplateProductPage from './TemplateProductPage';

export default {
  name: 'ProductPageApp',
  components: {
    BFormRating,
    BCollapse,
    HeaderDesktop: () => import('@/components/home/HeaderDesktop'),
    Carousel: () => import('@/components//atoms/Carousel'),
    ReadMore: () => import('@/components/hotelFlight/atoms/Readmore'),
    CollapseList: () => import('@/components/hotelFlight/CollapseList'),
    ContentLoading: () => import('@/components/atoms/ContentLoading'),
    FlightItemApp: () => import('@/components/atoms/FlightItemApp'),
    PriceAreaApp: () => import('@/components/hotelFlight/ProductPriceAreaApp'),
    FooterDesktop: () => import('@/components/home/FooterDesktop'),
  },
  directives: {
    'b-toggle': VBToggle,
  },
  extends: TemplateProductPage,
  mixins: [bannerImageDestination, imageUrlMixin],
  data() {
    return {
      backLink: '',
      paxes: { adult: 0, child: 0, infant: 0 },
    };
  },
  computed: {
    addressString() {
      if (!this.product) return '';
      return this.categoryCode === 'Organize_tour_packages' ? this.product.hotels[0].cityName : this.product.hotels[0].address;
    },
  },
  watch: {
    lang() {
      this.updateLabelWithLang();

      this.personString = this.getPersonString(
        this.paxes.adult, this.paxes.child, this.paxes.infant,
      );
      this.getBetweenDateString();
      this.getItineraryDetail();
    },
  },
  async created() {
    this.$store.commit('SET_CURRENT_PAGE', 'product');
    document.body.classList.add('productPage');
    this.activeHotel = this.$route.query.laId;

    window.sessionStorage.setItem('dealType', this.query.categoryId);
    this.backLink = `/deal-filter/app-desktop?channel=App`;

    if (this.subjectCategory.length === 0) {
      this.$store.dispatch('FETCH_SUBJECT_CATEGORY');
    }

    window.sessionStorage.setItem('marketerURL', this.$route.fullPath);
    window.sessionStorage.setItem('BookingDataID', '');
    const isBlankData = await this.fetchData();
    if (!isBlankData) return;

    this.analysisProductData();

    this.updateLabelWithLang();
  },
  mounted() {
    if (this.isOdysseySite) this.$store.commit('SET_SELECTED_ODYSSEY_AGENT_CODE', this.query.odyAgentCode || this.agentContent.odyAgentCode);
  },
  methods: {
    gotoHome() {
      this.$router.push('/');
    },
    async fetchData() {
      // this.discounted = Number(this.query.discount);

      if (!this.query.packId || !this.query.laId || !this.query.categoryId || !this.query.dateFrom || !this.query.flights || !this.query.adult
        || !this.query.roomType || !this.query.roomClass) {
        this.$bvModal.msgBoxOk(
          this.$t('search-result.dont-remove-query'),
          {
            title: this.$t('product-page.expire-title'),
            dialogClass: 'noSearchResult',
            okVariant: 'success',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true,
          },
        );
        return false;
      }

      await this.$store.dispatch('FETCH_PRODUCT', this.query);

      if (this.product?.hotels === undefined || this.product?.hotels?.length === 0) {
        this.$bvModal
          .msgBoxOk(this.$t('product-page.expire-message'), {
            title: this.$t('product-page.expire-title'),
            okVariant: 'danger',
            okTitle: this.$t('product-page.go-back'),
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true,
          })
          .then(() => {
            this.$router.push('/');
          })
          .catch(() => {
            // An error occurred
          });
        return false;
      }

      // if (document.querySelector('.st-content')) document.querySelector('.st-content').scrollTo(0, 0);
      return true;
    },
    getItineraryDetail() {
      if (this.categoryCode === 'Organize_tour_packages') {
        this.description_t1 = this.product.Itinerary?.include;
        if (this.product?.Itinerary?.itineraryDetail) {
          this.description_t2_fixed = this.product.Itinerary.itineraryDetail
            .filter((item, index) => index < 2)
            .map((item, index) => `<h3><img src="${this.speedSizeDomain}/assets/img/app/icon4.png" alt=""> <p>${this.$t('product-page.day')} ${index + 1}. ${item.header}</h3>${item.text}</p>`).join('<br>');
          this.description_t2 = this.product.Itinerary.itineraryDetail.filter((item, index) => index > 1)
            .map((item, index) => `<h3><img src="${this.speedSizeDomain}/assets/img/app/icon4.png" alt=""> <p>${this.$t('product-page.day')} ${index + 3}. ${item.header}</h3>${item.text}</p>`)
            .join('<br>');
        }
        this.description_t3 = this.product.notesTranslation[this.lang];
      }
    },
    getBetweenDateString() {
      if (this.product && this.product.flights) {
        const checkIn = dayjs(this.product.flights[0]?.FlightDetail[0]?.FL_Date).add(Number(this.product.hotel_shift_1), 'day'),
          checkOut = checkIn.add(Number(this.product.duration_1), 'day');
        if (this.lang === 'he') {
          this.tripDuration = `${this.getWeek(checkIn)} ${dayjs(checkIn).format('DD/MM/YY')} - ${this.getWeek(checkOut)} ${dayjs(checkOut).format('DD/MM/YY')} (${this.hotels[0]?.duration} ${this.$t('search-result.night')})`;
          this.betweenDateString = this.tripDuration;
        } else {
          this.tripDuration = `${this.getWeek(checkIn)} ${dayjs(checkIn).format('DD/MM/YY')} -
            ${this.getWeek(checkOut)} ${dayjs(checkOut).format('DD/MM/YY')} (${this.hotels[0]?.duration} ${this.$t('search-result.night')})`;
          this.betweenDateString = this.tripDuration;
        }
      }
    },
    updateLabelWithLang() {
      if (this.product && this.product.translations) {
        const hotelID = this.product.hotels[0].La_ID;
        this.hotelName = this.product.translations.hotelName[hotelID][this.lang] || this.product.hotels[0].hotelName;
        const hotelCityCode = this.product?.hotels[0]?.city;
        this.categoryName = this.product.category?.name[this.lang] || this.product.category?.name?.en;
        this.categoryName = this.categoryName.replace('חבילות נופש', 'חבילת נופש');
        this.destinationName = this.product.translations?.city[hotelCityCode][this.lang] || this.product?.hotels[0]?.cityName;
        const basisCode = this.hotels[this.idxHotel]?.basisName;
        this.basisName = this.product.translations?.basisName?.[basisCode]?.[this.lang] ?? basisCode;

        const body = this.categoryCode === 'Organize_tour_packages' ? {
          dest: Object.keys(this.product.translations.city)[0],
          yearMonth: this.query.dateFrom.substr(0, 7),
          subjectIds: this.product ? this.subjectCategory.find((sub) => (sub.subjectCodes.includes(String(this.product.subj_id)))).id : '',
          categoryId: this.query.categoryId,
          adult: this.query.adult,
          child: this.query.child,
          infant: this.query.infant,
        } : {
          dest: Object.keys(this.product.translations.city)[0],
          fromDate: this.query.dateFrom,
          // toDate: this.product?.flights[0]?.FlightDetail[1]?.FL_Date,
          toDate: dayjs(this.query.dateFrom).add(Number(this.product.duration_1), 'day').format('YYYY-MM-DD'),
          categoryId: this.query.categoryId,
          adult: this.query.adult,
          child: this.query.child,
          infant: this.query.infant,
        };

        if (this.query.utm_source) body.utm_source = this.query.utm_source;
        if (this.isLanding) body.utm_source = this.landingInfo.utm_source;
        if (this.marketerId) body.marketerId = this.marketerId;
        if (this.isFcAgentMarketerMode) body['fc-agent-mode'] = '';

        const searchQuery = `/search-result?${new URLSearchParams(body).toString()}`;

        this.linkData = {
          text: this.product.category?.name[this.lang] || this.product.category?.name?.en,
          dest: this.product.translations?.city[hotelCityCode][this.lang] || this.product?.hotels[0]?.cityName,
          stage: this.$t(this.stageLabel[this.bookingStage]),
          searchQuery,
        };

        this.remarks = this.product.remarkTranslation[this.lang] || this.product.remark;
        this.description_t3 = this.product.notesTranslation[this.lang];
      }
    },
  },
};

</script>

<style lang="less">
  .bonauf-desktop {
    .apparea {
      .body_warrper {
        .banner-area {
          .banner {
            .thumbnails:after {
              content: "";
              position: absolute;
              left: 0;
              right: 0;
              background-image: url(/assets/img/app/plane.png);
              width: 179px;
              height: 75px;
              top: -44px;
              background-repeat: no-repeat;
              background-size: contain;
              margin: auto;
              pointer-events: none;
            }
          }
        }
      }

      .flight-area {
        .flights-list {
          .container > .card.card-body .plaindetails-box:last-child {
            margin-top: 25px;
          }
        }
      }
    }
  }
</style>

<style lang="less" scoped>
  .bonauf-desktop {
    .apparea {
      max-width: 100%;

      .body_warrper {
        .row {
          margin: auto !important;
        }
      }

      .daily-area {
        margin-top: 50px;
        .heading-box {
          background: rgb(3,148,208);
          background: linear-gradient(90deg, rgba(3,148,208,1) 0%, rgba(132,219,255,1) 100%);
          box-sizing: border-box;
          padding: 30px 0;

          h4 {
            font-family: 'FbCoherentiSansBold';
            font-size: 34px;
            margin-bottom: 0;
            color: #fff;
            text-align: center;
            line-height: 40px;
          }
        }

        .readmore {
          max-width: 850px;
          margin: auto;
          margin-top: 30px;
          padding: 0px 20px;
        }
      }

      .flight-area {
        padding: 24px 0 0;
        color: #fff;
        text-align: center;
        margin-top: 40px;
        position: relative;
        z-index: 1;

        h3 {
          font-family: 'FbCoherentiSansBold';
          font-size: 28px;
          margin-bottom: 0;
        }

        .flights-list {
          max-width: 800px;
          margin: auto;

          .container > .card.card-body .plaindetails-box:last-child {
            margin-top: 25px;
          }

          .select-flight, .flight_only_area .select-flight {
            position: relative;
            margin: 20px 0px;
            z-index: 2;
          }
        }
      }
      .flight-area:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 170px;
        z-index: -1;
        background: rgb(3,148,208);
        background: linear-gradient(90deg, rgba(3,148,208,1) 0%, rgba(132,219,255,1) 100%);
      }

      .price-area {
        padding: 24px 0 0;
        color: #fff;
        text-align: center;
        margin-top: 40px;
        position: relative;
        z-index: 1;
      }
      .price-area:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 170px;
        z-index: -1;
        background: rgb(3,148,208);
        background: linear-gradient(90deg, rgba(3,148,208,1) 0%, rgba(132,219,255,1) 100%);
      }
    }
    .product-content {
      padding-bottom: 140px;
    }
  }
</style>
